import { fromHours, fromMinutes } from "../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { toString as toString_1, Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { PortWindWeather_Default_Z53C0511E, SensorId__get_Value, SensorId, PortWindWeatherResult_$reflection, SensorId_$reflection, SensorMetaData_$reflection, PortWindWeather_$reflection } from "../StenaWeather.Domain/PortWind.js";
import { anonRecord_type, unit_type, lambda_type, float64_type, string_type, bool_type, list_type, union_type, record_type, class_type, option_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Point_$reflection } from "../StenaWeather.Domain/Forecast.js";
import { choose as choose_1, chunkBySize, tryFind, toArray, reverse, ofArray, replicate, map, sortByDescending, tryHead, head, isEmpty, filter, singleton, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { msToKnots } from "../StenaWeather.Domain/Units.js";
import { curry2, int32ToString, safeHash, createObj, comparePrimitives, equals, round } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { ServerError_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PageTitle_titleWith, ErrorView_Cmd_error, ErrorView_Cmd_ofError } from "../SharedView.js";
import { map2, toArray as toArray_1, orElse, bind, map as map_1, value as value_132, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { onPortWindAPI, Cmd_OfAsync_eitherAsResult } from "../Server.js";
import { toString, compare } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { sleep } from "../fable_modules/fable-library-js.4.19.3/Async.js";
import { FSharpMap__TryFind, ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { tryHead as tryHead_1, empty as empty_1, choose, isEmpty as isEmpty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { ColumnModule_View } from "../Components/StenaWebUI/Core/Column.js";
import { RowModule_View } from "../Components/StenaWebUI/Core/Row.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { SpaceModule_View } from "../Components/StenaWebUI/Core/Space.js";
import PortWindDashboard$002Emodule from "../../src/StenaWeather.WeatherServiceUI.Client/Pages/PortWindDashboard.module.scss";
import { printf, toText, join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { faChartLine, faTh, faMapMarkedAlt, faMoon, faWater, faWind, faLongArrowAltUp, faTornado, faFan } from "@fortawesome/free-solid-svg-icons";
import { List_distinct } from "../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { map as map_2 } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, Line, Area, Legend, Tooltip, YAxis, XAxis, CartesianGrid } from "recharts";
import { rotateDirection180, round as round_1, timeInContext } from "./Common.js";
import { debounce } from "../Utils.js";
import { Impl_createRemoveOptions, Impl_adjustPassive } from "../Listeners.js";
import { useEffect } from "../fable_modules/Feliz.2.8.0/ReactInterop.js";
import { createDisposable, useCallbackRef } from "../fable_modules/Feliz.2.8.0/Internal.fs.js";
import { timeZoneContext } from "../Components/TimeZones/Context.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { min, max } from "../fable_modules/fable-library-js.4.19.3/Double.js";
import { IconModule_View } from "../Components/StenaWebUI/Icons/Icon.js";
import { SwitchModule_View } from "../Components/StenaWebUI/Forms/Switch.js";
import { DateTimeOffset_dateTimeWithTimezone, DateTimeOffset_dateTimeStr } from "../Components/TimeZones/Components.js";
import map_marker from "../../src/StenaWeather.WeatherServiceUI.Client/images/map-marker.svg";
import { TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import { Marker_View } from "../Components/Leaflet/Marker.js";
import { MapContainer_View } from "../Components/Leaflet/Container.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { directionArrow, ship_at_quay as ship_at_quay_2 } from "./ship_at_quay.svg.js";
import { op_UnaryNegation_Int32 } from "../fable_modules/fable-library-js.4.19.3/Int32.js";

export const autoReloadPeriod = fromMinutes(1);

export class PortWindData extends Record {
    constructor(Weather, Location, LastUpdated) {
        super();
        this.Weather = Weather;
        this.Location = Location;
        this.LastUpdated = LastUpdated;
    }
}

export function PortWindData_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.PortWindData", [], PortWindData, () => [["Weather", option_type(PortWindWeather_$reflection())], ["Location", option_type(Point_$reflection())], ["LastUpdated", class_type("System.DateTimeOffset")]]);
}

export class MainView extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Dashboard", "TrendGraph"];
    }
}

export function MainView_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.MainView", [], MainView, () => [[], []]);
}

export class Color extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Font", "Background", "Inactive", "BoxFont", "BoxBackground", "TrendLineWind", "TrendLineGustWind", "TrendLineAverageWind", "TrendLineWindDirection", "TrendLineTideHeight", "TrendLineTidalFlowSpeed", "TrendLineWaveHeight"];
    }
}

export function Color_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.Color", [], Color, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Theme extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Light", "Dark"];
    }
}

export function Theme_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.Theme", [], Theme, () => [[], []]);
}

export function ThemeModule_colorMapping(theme) {
    if (theme.tag === 1) {
        return (_arg_1) => ((_arg_1.tag === 1) ? "var(--lhds-color-ui-900)" : ((_arg_1.tag === 2) ? "var(--lhds-color-ui-800)" : ((_arg_1.tag === 3) ? "var(--lhds-color-blue-600)" : ((_arg_1.tag === 4) ? "var(--lhds-color-blue-900)" : ((_arg_1.tag === 5) ? "var(--lhds-color-blue-800)" : ((_arg_1.tag === 6) ? "var(--lhds-color-blue-500)" : ((_arg_1.tag === 7) ? "var(--lhds-color-blue-600)" : ((_arg_1.tag === 8) ? "var(--lhds-color-orange-900)" : ((_arg_1.tag === 9) ? "var(--lhds-color-green-900)" : ((_arg_1.tag === 10) ? "var(--lhds-color-green-600)" : ((_arg_1.tag === 11) ? "var(--lhds-color-turquoise-900)" : "var(--lhds-color-blue-400)")))))))))));
    }
    else {
        return (_arg) => ((_arg.tag === 1) ? "var(--lhds-color-ui-100)" : ((_arg.tag === 2) ? "var(--lhds-color-ui-400)" : ((_arg.tag === 3) ? "var(--lhds-color-ui-100)" : ((_arg.tag === 4) ? "var(--lhds-color-blue-600)" : ((_arg.tag === 5) ? "var(--lhds-color-blue-500)" : ((_arg.tag === 6) ? "var(--lhds-color-blue-800)" : ((_arg.tag === 7) ? "var(--lhds-color-blue-300)" : ((_arg.tag === 8) ? "var(--lhds-color-orange-600)" : ((_arg.tag === 9) ? "var(--lhds-color-green-600)" : ((_arg.tag === 10) ? "var(--lhds-color-green-300)" : ((_arg.tag === 11) ? "var(--lhds-color-turquoise-600)" : "var(--lhds-color-ui-800)")))))))))));
    }
}

export class State extends Record {
    constructor(Sensors, SelectedSensor, Weather, WeatherHistory, UnitsMetersPerSeconds, MainView, Theme) {
        super();
        this.Sensors = Sensors;
        this.SelectedSensor = SelectedSensor;
        this.Weather = Weather;
        this.WeatherHistory = WeatherHistory;
        this.UnitsMetersPerSeconds = UnitsMetersPerSeconds;
        this.MainView = MainView;
        this.Theme = Theme;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.State", [], State, () => [["Sensors", list_type(SensorMetaData_$reflection())], ["SelectedSensor", option_type(SensorId_$reflection())], ["Weather", option_type(PortWindData_$reflection())], ["WeatherHistory", list_type(PortWindWeather_$reflection())], ["UnitsMetersPerSeconds", bool_type], ["MainView", MainView_$reflection()], ["Theme", Theme_$reflection()]]);
}

export function State_get_Empty() {
    return new State(empty(), undefined, undefined, empty(), true, new MainView(0, []), new Theme(0, []));
}

export function State__get_UnitText(state) {
    if (state.UnitsMetersPerSeconds) {
        return "m/s";
    }
    else {
        return "knots";
    }
}

export function State__get_UnitValue(state) {
    const f1_1 = state.UnitsMetersPerSeconds ? ((value) => value) : (msToKnots);
    return (arg_1) => round(f1_1(arg_1), 3);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetSensors", "SensorsLoaded", "SensorSelected", "GetWeather", "WeatherLoaded", "UnitsChanged", "MainViewSwitched", "ThemeSwitched"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SensorMetaData_$reflection()), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SensorMetaData_$reflection())]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", SensorId_$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PortWindWeatherResult_$reflection(), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", PortWindWeatherResult_$reflection()]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", bool_type]], [], []]);
}

export function init() {
    return [State_get_Empty(), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })))];
}

export function update(msg, state) {
    let msg_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [state, ErrorView_Cmd_ofError(msg.fields[0].fields[0])];
            }
            else {
                const sensors_1 = filter((s) => {
                    if (s.Enabled) {
                        return s.Visible;
                    }
                    else {
                        return false;
                    }
                }, msg.fields[0].fields[0]);
                if (isEmpty(sensors_1)) {
                    return [state, ErrorView_Cmd_error("Sensor list is empty")];
                }
                else {
                    return [new State(sensors_1, state.SelectedSensor, state.Weather, state.WeatherHistory, state.UnitsMetersPerSeconds, state.MainView, state.Theme), (msg_1 = (new Msg(2, [defaultArg(state.SelectedSensor, head(sensors_1).SensorId)])), singleton((dispatch) => {
                        dispatch(msg_1);
                    }))];
                }
            }
        case 2:
            return [new State(state.Sensors, msg.fields[0], state.Weather, state.WeatherHistory, state.UnitsMetersPerSeconds, state.MainView, state.Theme), singleton((dispatch_1) => {
                dispatch_1(new Msg(3, []));
            })];
        case 3:
            return [state, Cmd_OfAsync_eitherAsResult(() => onPortWindAPI((x_1) => x_1.GetWeather(value_132(state.SelectedSensor), fromHours(3), fromHours(1))), (Item_1) => (new Msg(4, [Item_1])))];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [state, ErrorView_Cmd_ofError(msg.fields[0].fields[0])];
            }
            else {
                const r = msg.fields[0].fields[0];
                return [new State(state.Sensors, state.SelectedSensor, new PortWindData(tryHead(sortByDescending((x_2) => x_2.DateTime, r.Weather, {
                    Compare: compare,
                })), r.Location, r.LastUpdated), r.Weather, state.UnitsMetersPerSeconds, state.MainView, state.Theme), Cmd_OfAsyncWith_perform((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, () => sleep(~~autoReloadPeriod), undefined, () => (new Msg(3, [])))];
            }
        case 5:
            return [new State(state.Sensors, state.SelectedSensor, state.Weather, state.WeatherHistory, msg.fields[0], state.MainView, state.Theme), Cmd_none()];
        case 6:
            return [new State(state.Sensors, state.SelectedSensor, state.Weather, state.WeatherHistory, state.UnitsMetersPerSeconds, equals(state.MainView, new MainView(0, [])) ? (new MainView(1, [])) : (new MainView(0, [])), state.Theme), Cmd_none()];
        case 7:
            return [new State(state.Sensors, state.SelectedSensor, state.Weather, state.WeatherHistory, state.UnitsMetersPerSeconds, state.MainView, equals(state.Theme, new Theme(0, [])) ? (new Theme(1, [])) : (new Theme(0, []))), Cmd_none()];
        default:
            return [state, Cmd_OfAsync_eitherAsResult(() => onPortWindAPI((x) => x.GetSensors()), (Item) => (new Msg(1, [Item])))];
    }
}

export function ViewHelpers_mkColumnWith(p, x) {
    const p_1_1 = ofList(map((value) => value, toList(delay(() => append(p, delay(() => singleton_1(["children", x])))))), {
        Compare: comparePrimitives,
    });
    return createElement(ColumnModule_View, {
        p: p_1_1,
    });
}

export function ViewHelpers_mkColumn(x) {
    const p_1_1 = ofList(map((value) => value, singleton(["children", x])), {
        Compare: comparePrimitives,
    });
    return createElement(ColumnModule_View, {
        p: p_1_1,
    });
}

export function ViewHelpers_mkRowWith(p, x) {
    const p_1_1 = ofList(map((value) => value, toList(delay(() => append(p, delay(() => singleton_1(["children", x])))))), {
        Compare: comparePrimitives,
    });
    return createElement(RowModule_View, {
        p: p_1_1,
    });
}

export function ViewHelpers_mkRow(x) {
    const p_1_1 = ofList(map((value) => value, singleton(["children", x])), {
        Compare: comparePrimitives,
    });
    return createElement(RowModule_View, {
        p: p_1_1,
    });
}

export function ViewHelpers_divWith(p, x) {
    return createElement("div", createObj(toList(delay(() => append(p, delay(() => singleton_1(["children", reactApi.Children.toArray(Array.from(x))])))))));
}

export function ViewHelpers_spanWith(p, x) {
    return createElement("span", createObj(toList(delay(() => append(p, delay(() => singleton_1(["children", reactApi.Children.toArray(Array.from(x))])))))));
}

export function ViewHelpers_spaceN(n) {
    let p_1_1;
    return replicate(n, (p_1_1 = ofList(map((value) => value, empty()), {
        Compare: comparePrimitives,
    }), createElement(SpaceModule_View, {
        p: p_1_1,
    })));
}

export function ViewHelpers_mkTextWith(f, color, size, p, s) {
    return f(singleton(["style", createObj(toList(delay(() => append(singleton_1(["color", color]), delay(() => append(singleton_1(["fontSize", size]), delay(() => p)))))))]), singleton(s));
}

export function ViewHelpers_mkTextSizeUnit(color, size, p, s) {
    return ViewHelpers_mkTextWith(ViewHelpers_spanWith, color, size, p, s);
}

export function ViewHelpers_mkText(color, size, p, s) {
    return ViewHelpers_mkTextWith(ViewHelpers_spanWith, color, size + "vmin", p, s);
}

export function ViewHelpers_leftRightColumns(isSmall, width, left, right) {
    if (isSmall) {
        return ViewHelpers_mkColumn(toList(delay(() => append(singleton_1(ViewHelpers_mkRow(singleton(ViewHelpers_mkColumn(left)))), delay(() => append(ViewHelpers_spaceN(1), delay(() => singleton_1(ViewHelpers_mkRow(singleton(ViewHelpers_mkColumn(right)))))))))));
    }
    else {
        return ViewHelpers_mkRow(singleton(ViewHelpers_mkColumnWith([["width", width]], singleton(ViewHelpers_mkRowWith([["justifyContent", "space-between"]], ofArray([ViewHelpers_mkColumn(left), ViewHelpers_mkColumnWith([["justifyContent", "flex-end"]], right)]))))));
    }
}

export const ViewHelpers_cn = (() => {
    const styles = PortWindDashboard$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export class WeatherValue_TrendType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Line", "Area"];
    }
}

export function WeatherValue_TrendType_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.WeatherValue.TrendType", [], WeatherValue_TrendType, () => [[], []]);
}

export class WeatherValue_Unit extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MsOrKnots", "Degree", "Meter"];
    }
}

export function WeatherValue_Unit_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.WeatherValue.Unit", [], WeatherValue_Unit, () => [[], [], []]);
}

export class WeatherValue_Definition extends Record {
    constructor(Label, ChartName, Unit, Icon, ChartType, ChartColor, GetValue) {
        super();
        this.Label = Label;
        this.ChartName = ChartName;
        this.Unit = Unit;
        this.Icon = Icon;
        this.ChartType = ChartType;
        this.ChartColor = ChartColor;
        this.GetValue = GetValue;
    }
}

export function WeatherValue_Definition_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.WeatherValue.Definition", [], WeatherValue_Definition, () => [["Label", string_type], ["ChartName", string_type], ["Unit", WeatherValue_Unit_$reflection()], ["Icon", string_type], ["ChartType", WeatherValue_TrendType_$reflection()], ["ChartColor", Color_$reflection()], ["GetValue", lambda_type(State_$reflection(), lambda_type(PortWindWeather_$reflection(), option_type(float64_type)))]]);
}

export class WeatherValue_WeatherBox extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["WeatherValue", "WeatherDirection", "TrendChart", "MapBox", "RotatingDirectionSvg"];
    }
}

export function WeatherValue_WeatherBox_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWindDashboard.WeatherValue.WeatherBox", [], WeatherValue_WeatherBox, () => [[["Item", WeatherValue_Definition_$reflection()]], [["Item", WeatherValue_Definition_$reflection()]], [["Item", anonRecord_type(["Label", string_type], ["OnClick", lambda_type(unit_type, unit_type)])]], [["Item", anonRecord_type(["Label", string_type])]], [["Item", anonRecord_type(["Flip", bool_type], ["Label", string_type], ["Svg", class_type("Fable.React.ReactElement")], ["SvgArrow", class_type("Fable.React.ReactElement")], ["SvgTransform", list_type(class_type("Feliz.ISvgAttribute"))], ["WholeSvgTransform", list_type(class_type("Feliz.ISvgAttribute"))])]]]);
}

export const WeatherValue_values = ofArray([new WeatherValue_Definition("Actual", "Wind", new WeatherValue_Unit(0, []), faFan, new WeatherValue_TrendType(0, []), new Color(5, []), (state, point) => map_1(State__get_UnitValue(state), point.WindSpeed)), new WeatherValue_Definition("Gust wind", "Gust wind", new WeatherValue_Unit(0, []), faTornado, new WeatherValue_TrendType(0, []), new Color(6, []), (state_1, point_1) => map_1(State__get_UnitValue(state_1), point_1.GustWindSpeed)), new WeatherValue_Definition("Max gust wind", "Max gust wind", new WeatherValue_Unit(0, []), faTornado, new WeatherValue_TrendType(0, []), new Color(6, []), (state_2, point_2) => map_1(State__get_UnitValue(state_2), point_2.MaxGustWindSpeed)), new WeatherValue_Definition("Wind direction", "Direction", new WeatherValue_Unit(1, []), faLongArrowAltUp, new WeatherValue_TrendType(0, []), new Color(8, []), (_arg, point_3) => map_1((value) => value, point_3.WindDirection)), new WeatherValue_Definition("Average", "Average wind", new WeatherValue_Unit(0, []), faWind, new WeatherValue_TrendType(1, []), new Color(7, []), (state_3, point_4) => map_1(State__get_UnitValue(state_3), point_4.WindSpeedAverage)), new WeatherValue_Definition("Wave height", "Wave height", new WeatherValue_Unit(2, []), faWater, new WeatherValue_TrendType(0, []), new Color(11, []), (_arg_1, point_5) => map_1((value_1) => value_1, point_5.WaveHeight)), new WeatherValue_Definition("Tide height", "Tide height", new WeatherValue_Unit(2, []), faMoon, new WeatherValue_TrendType(0, []), new Color(9, []), (_arg_2, point_6) => map_1((value_2) => value_2, point_6.TideHeight)), new WeatherValue_Definition("Tidal flow speed", "Tidal flow speed", new WeatherValue_Unit(0, []), faMoon, new WeatherValue_TrendType(0, []), new Color(10, []), (state_4, point_7) => map_1(State__get_UnitValue(state_4), point_7.TidalFlowSpeed))]);

export function Graph_Recharts_area_yAxisId(x) {
    return ["yAxisId", x];
}

export function Graph_Recharts_xAxis_minTickGap(x) {
    return ["minTickGap", x];
}

export function Graph_Recharts_xAxis_tickStyle(p) {
    return ["tick", createObj(p)];
}

export function Graph_Recharts_yAxis_tickStyle(p) {
    return ["tick", createObj(p)];
}

export function Graph_Recharts_yAxis_tickFormatter(f) {
    return ["tickFormatter", f];
}

export const Graph_Recharts_composedChart_cursor_pointer = ["cursor", "pointer"];

export function Graph_trendChart(ctx, smallAspect, state) {
    let valuesNotEmpty, axisTypes, properties_10, elements, value_65;
    const theme = ThemeModule_colorMapping(state.Theme);
    const isSmall = smallAspect != null;
    const unitText = State__get_UnitText(state);
    const data = reverse(state.WeatherHistory);
    const fontSize = (isSmall ? 1 : 2) | 0;
    const fontColor = theme(isSmall ? (new Color(3, [])) : (new Color(0, [])));
    const styleAttributes = ofArray([["fontWeight", "bold"], ["fontSize", fontSize + "vmin"]]);
    let x_4;
    const properties_11 = ofArray([["children", (valuesNotEmpty = filter((d) => !isEmpty_1(choose((x) => d.GetValue(state, x), data)), WeatherValue_values), (axisTypes = List_distinct(map((d_1) => d_1.Unit, valuesNotEmpty), {
        Equals: equals,
        GetHashCode: safeHash,
    }), (properties_10 = ofArray([Graph_Recharts_composedChart_cursor_pointer, ["width", 100], ["height", 100], ["data", toArray(data)], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(undefined, 0),
        left: defaultArg(undefined, 0),
        bottom: defaultArg(undefined, 0),
    }], (elements = toList(delay(() => {
        let properties;
        return append(singleton_1((properties = singleton(["strokeDasharray", join(" ", map_2(int32ToString, new Int32Array([3, 3])))]), reactApi_1.createElement(CartesianGrid, createObj(properties)))), delay(() => {
            let properties_1;
            return append(singleton_1((properties_1 = ofArray([["dataKey", (w) => timeInContext(ctx, (_arg) => toString(_arg, "HH:mm"), w.DateTime)], Graph_Recharts_xAxis_minTickGap(10), ["tickMargin", isSmall ? 10 : 25], ["height", isSmall ? 35 : 70], ["angle", -70], Graph_Recharts_xAxis_tickStyle(singleton(["fill", fontColor]))]), reactApi_1.createElement(XAxis, createObj(properties_1)))), delay(() => append(map((_arg_1) => {
                switch (_arg_1.tag) {
                    case 1: {
                        const properties_3 = ofArray([["yAxisId", toString_1(_arg_1)], ["orientation", "left"], Graph_Recharts_yAxis_tickFormatter((x_2) => {
                            const arg = ~~x_2 | 0;
                            return toText(printf("%i°"))(arg);
                        }), Graph_Recharts_yAxis_tickStyle(singleton(["fill", fontColor]))]);
                        return reactApi_1.createElement(YAxis, createObj(properties_3));
                    }
                    case 2: {
                        const properties_4 = ofArray([["yAxisId", toString_1(_arg_1)], ["orientation", "right"], ["label", {
                            value: "m",
                            angle: -90,
                            offset: 0,
                            position: ["position", "inside"],
                            fill: theme(isSmall ? (new Color(3, [])) : (new Color(0, []))),
                            dx: isSmall ? 0 : 10,
                        }], Graph_Recharts_yAxis_tickStyle(singleton(["fill", fontColor]))]);
                        return reactApi_1.createElement(YAxis, createObj(properties_4));
                    }
                    default: {
                        const properties_2 = ofArray([["yAxisId", toString_1(_arg_1)], ["orientation", "right"], ["label", {
                            value: unitText,
                            angle: -90,
                            offset: 0,
                            position: ["position", "inside"],
                            fill: theme(isSmall ? (new Color(3, [])) : (new Color(0, []))),
                        }], Graph_Recharts_yAxis_tickStyle(singleton(["fill", fontColor]))]);
                        return reactApi_1.createElement(YAxis, createObj(properties_2));
                    }
                }
            }, axisTypes), delay(() => {
                let properties_6;
                return append(!isSmall ? singleton_1((properties_6 = singleton(["itemStyle", {
                    height: 20,
                }]), reactApi_1.createElement(Tooltip, createObj(properties_6)))) : empty_1(), delay(() => append(singleton_1(reactApi_1.createElement(Legend, {
                    iconType: "square",
                    formatter: (delegateArg, delegateArg_1, delegateArg_2) => ViewHelpers_mkTextWith(ViewHelpers_spanWith, fontColor, fontSize + "vmin", [], delegateArg),
                })), delay(() => map((d_2) => {
                    if (d_2.ChartType.tag === 1) {
                        const properties_9 = ofArray([["name", d_2.ChartName], ["type", "monotone"], ["dataKey", curry2(d_2.GetValue)(state)], ["stroke", theme(d_2.ChartColor)], ["fill", theme(d_2.ChartColor)], Graph_Recharts_area_yAxisId(toString_1(d_2.Unit))]);
                        return reactApi_1.createElement(Area, createObj(properties_9));
                    }
                    else {
                        const properties_8 = ofArray([["name", d_2.ChartName], ["type", "monotone"], ["dataKey", curry2(d_2.GetValue)(state)], ["stroke", theme(d_2.ChartColor)], ["strokeWidth", isSmall ? 1 : 3], ["dot", !isSmall], ["yAxisId", toString_1(d_2.Unit)]]);
                        return reactApi_1.createElement(Line, createObj(properties_8));
                    }
                }, valuesNotEmpty)))));
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elements))])]), reactApi_1.createElement(ComposedChart, createObj(properties_10)))))], (value_65 = (98 + "%"), (equals(value_65, 100 + "%") ? true : equals(value_65, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_65]), ["aspect", defaultArg(smallAspect, 2)]]);
    x_4 = reactApi_1.createElement(ResponsiveContainer, createObj(properties_11));
    return createElement("div", {
        style: createObj(styleAttributes),
        children: reactApi.Children.toArray([x_4]),
    });
}

export function svg_transform_rotateCenter(deg, centerX, centerY) {
    return ["transform", ((((("rotate(" + deg) + ", ") + centerX) + ", ") + centerY) + ")"];
}

export function svg_width(value) {
    return ["width", value];
}

export function svg_height(value) {
    return ["height", value];
}

export function PortWindDashboardView() {
    let patternInput;
    let initial;
    const Width = window.innerWidth;
    initial = {
        Height: window.innerHeight,
        Width: Width,
    };
    patternInput = reactApi.useState(initial);
    const viewPort = patternInput[0];
    const action_1 = (_arg) => {
        debounce()(200)(() => {
            let Width_1;
            patternInput[1]((Width_1 = window.innerWidth, {
                Height: window.innerHeight,
                Width: Width_1,
            }));
        })();
    };
    const options_1 = undefined;
    let addOptions;
    const dependencies_1 = [options_1];
    addOptions = reactApi.useMemo(() => Impl_adjustPassive(options_1), dependencies_1);
    let removeOptions;
    const dependencies_1_1 = [options_1];
    removeOptions = reactApi.useMemo(() => Impl_createRemoveOptions(options_1), dependencies_1_1);
    let fn;
    const dependencies_1_2 = [action_1];
    fn = reactApi.useMemo(() => ((arg) => {
        action_1(arg);
    }), dependencies_1_2);
    useEffect(useCallbackRef(() => {
        if (addOptions == null) {
            window.addEventListener("resize", fn);
        }
        else {
            const options_1_1 = addOptions;
            window.addEventListener("resize", fn, options_1_1);
        }
        return createDisposable(() => {
            if (removeOptions == null) {
                window.removeEventListener("resize", fn);
            }
            else {
                const options_2 = removeOptions;
                window.removeEventListener("resize", fn, options_2);
            }
        });
    }));
    const ctx = reactApi.useContext(timeZoneContext);
    const patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg_1, _arg_1_1) => {
    }), undefined, []);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const theme = ThemeModule_colorMapping(state_1.Theme);
    const weather = state_1.Weather;
    const sensor = bind((sId) => tryFind((x) => equals(x.SensorId, sId), state_1.Sensors), state_1.SelectedSensor);
    let patternInput_2;
    const baseWidth = viewPort.Width - 250;
    const baseWidthPercent = (baseWidth / viewPort.Width) * 100;
    const columns = max(1, min(3, ~~(baseWidth / 333))) | 0;
    patternInput_2 = [columns, (baseWidthPercent / columns) - (columns * 1)];
    const columns_1 = patternInput_2[0] | 0;
    const columnWidth_1 = patternInput_2[1];
    return ViewHelpers_divWith([["className", `theme-${toString_1(state_1.Theme).toLocaleLowerCase()}`], ["style", {
        backgroundColor: theme(new Color(1, [])),
        color: theme(new Color(0, [])),
        height: 100 + "%",
    }]], toList(delay(() => {
        let color_3;
        return append(singleton_1(ViewHelpers_leftRightColumns(false, 100 + "%", singleton(ViewHelpers_divWith([["style", {
            color: theme(new Color(0, [])),
        }]], singleton(PageTitle_titleWith((color_3 = theme(new Color(0, [])), (s) => ViewHelpers_mkText(color_3, 4, [], s)), "PortWind Dashboard", "")))), empty())), delay(() => append(singleton_1(ViewHelpers_mkText(theme(new Color(0, [])), 2, [], "Choose port to see wind statistics")), delay(() => append(ViewHelpers_spaceN(1), delay(() => {
            let w_3, weather_1, lastUpdated_2;
            const header = (lastUpdated_1) => {
                let p_1_2;
                return ViewHelpers_leftRightColumns(columns_1 <= 1, ((columns_1 * columnWidth_1) + (((columns_1 - 1) * 2) * 1)) + "vw", singleton(ViewHelpers_mkRow(toList(delay(() => {
                    let elems;
                    return append(singleton_1(createElement("select", createObj(ofArray([["style", {
                        fontSize: 2 + "vmin",
                        color: theme(new Color(3, [])),
                        backgroundColor: theme(new Color(4, [])),
                        borderRadius: 0.5 + "vmin",
                        borderWidth: 0,
                        borderRight: ((((1 + "vmin") + " ") + "solid") + " ") + theme(new Color(4, [])),
                        marginRight: 0.5 + "vmin",
                        padding: 0.5 + "vmin",
                    }], ["onChange", (ev_1) => {
                        dispatch(new Msg(2, [new SensorId(ev_1.target.value)]));
                    }], (elems = map((s_1) => createElement("option", createObj(toList(delay(() => append(singleton_1(["children", s_1.Name]), delay(() => append(singleton_1(["value", SensorId__get_Value(s_1.SensorId)]), delay(() => (equals(state_1.SelectedSensor, s_1.SensorId) ? singleton_1(["selected", true]) : empty_1()))))))))), state_1.Sensors), ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(ViewHelpers_spaceN(2), delay(() => {
                        let t;
                        return append(singleton_1(IconModule_View(ofList(map((value_49) => value_49, ofArray([(t = faMapMarkedAlt, ["icon", t]), ["color", theme(new Color(2, []))], ["size", 5 + "vmin"]])), {
                            Compare: comparePrimitives,
                        }))), delay(() => append(ViewHelpers_spaceN(2), delay(() => append(singleton_1(ViewHelpers_spanWith([["onClick", (_arg_2) => {
                            dispatch(new Msg(6, []));
                        }], ["style", {
                            cursor: "pointer",
                        }]], singleton(IconModule_View(ofList(map((value_54) => value_54, toList(delay(() => append(singleton_1(["icon", (state_1.MainView.tag === 1) ? faTh : faChartLine]), delay(() => append(singleton_1(["color", theme(new Color(4, []))]), delay(() => singleton_1(["size", 5 + "vmin"])))))))), {
                            Compare: comparePrimitives,
                        }))))), delay(() => append(ViewHelpers_spaceN(2), delay(() => {
                            let p_1_5, p_1_1, p_1_6;
                            return singleton_1(ViewHelpers_mkColumnWith([["justifyContent", "center"]], singleton(ViewHelpers_mkRow(ofArray([ViewHelpers_mkColumnWith([["justifyContent", "center"]], singleton(ViewHelpers_mkText(theme(new Color(0, [])), 2, [], "knots"))), (p_1_5 = ofList(map((value_55) => value_55, empty()), {
                                Compare: comparePrimitives,
                            }), createElement(SpaceModule_View, {
                                p: p_1_5,
                            })), ViewHelpers_divWith([["style", {
                                "--swui-field-bg-enabled": theme(new Color(4, [])),
                                "--swui-primary-action-color": theme(new Color(4, [])),
                                "--swui-handle-bg-enabled-color": theme(new Color(3, [])),
                                "--swui-field-border-color": theme(new Color(1, [])),
                            }]], singleton((p_1_1 = ofList(map((value_10) => value_10, ofArray([["value", state_1.UnitsMetersPerSeconds], ["onValueChange", (arg_1) => {
                                dispatch(new Msg(5, [arg_1]));
                            }]])), {
                                Compare: comparePrimitives,
                            }), createElement(SwitchModule_View, {
                                p: p_1_1,
                            })))), (p_1_6 = ofList(map((value_56) => value_56, empty()), {
                                Compare: comparePrimitives,
                            }), createElement(SpaceModule_View, {
                                p: p_1_6,
                            })), ViewHelpers_mkColumnWith([["justifyContent", "center"]], singleton(ViewHelpers_mkText(theme(new Color(0, [])), 2, [], "m/s")))])))));
                        }))))))));
                    }))));
                })))), singleton(ViewHelpers_mkRow(ofArray([ViewHelpers_mkText(theme(new Color(0, [])), 2, [], "Last updated: "), (p_1_2 = ofList(map((value_22) => value_22, empty()), {
                    Compare: comparePrimitives,
                }), createElement(SpaceModule_View, {
                    p: p_1_2,
                })), ViewHelpers_mkText(theme(new Color(0, [])), 2, [], DateTimeOffset_dateTimeStr(DateTimeOffset_dateTimeWithTimezone(lastUpdated_1)))]))));
            };
            const dashboardView = (w) => {
                const x_33 = map((arg_4) => ViewHelpers_mkRow(map((x_7) => {
                    let innerContent;
                    const iconMargin = singleton(["marginRight", 3 + "vw"]);
                    const mkContentWeatherValue = (d_1, iconProps) => {
                        const matchValue_1 = d_1.GetValue(state_1, w);
                        if (matchValue_1 != null) {
                            const value_60 = matchValue_1;
                            let patternInput_3;
                            const matchValue_2 = d_1.Unit;
                            patternInput_3 = ((matchValue_2.tag === 2) ? [round_1, "m"] : ((matchValue_2.tag === 1) ? [(x_10) => (`${round_1(x_10)}°`), ""] : [round_1, State__get_UnitText(state_1)]));
                            return ViewHelpers_mkRow(ofArray([ViewHelpers_mkColumn(singleton(ViewHelpers_divWith(iconProps(value_60), singleton(IconModule_View(ofList(map((value_62) => value_62, ofArray([["icon", d_1.Icon], ["color", theme(new Color(3, []))], ["size", 12 + "vmin"]])), {
                                Compare: comparePrimitives,
                            })))))), ViewHelpers_mkColumnWith([["justifyContent", "center"]], singleton(ViewHelpers_spanWith([], ofArray([ViewHelpers_mkTextSizeUnit(theme(new Color(3, [])), 6 + "vmax", [["fontWeight", "bold"]], patternInput_3[0](value_60)), ViewHelpers_mkText(theme(new Color(3, [])), 3, [["marginLeft", 0.4 + "vw"]], " " + patternInput_3[1])]))))]));
                        }
                        else {
                            return ViewHelpers_mkRow(empty());
                        }
                    };
                    const mkContent = (props_10, label_1, rowItem) => {
                        const x_12 = ViewHelpers_mkColumnWith([["alignItems", "center"], ["width", "100%"], ["height", "100%"]], toList(delay(() => append(ViewHelpers_spaceN(2), delay(() => append(singleton_1(ViewHelpers_mkRow(singleton(ViewHelpers_mkText(theme(new Color(3, [])), 4, [], label_1)))), delay(() => append(singleton_1(ViewHelpers_mkRowWith([["height", "100%"]], singleton(ViewHelpers_mkColumnWith([["height", "100%"], ["alignItems", "center"], ["justifyContent", "center"]], singleton(rowItem))))), delay(() => ViewHelpers_spaceN(2))))))))));
                        if (equals(props_10, empty())) {
                            return x_12;
                        }
                        else {
                            return ViewHelpers_spanWith(props_10, singleton(x_12));
                        }
                    };
                    innerContent = ((_arg_3) => {
                        let elements_15;
                        switch (_arg_3.tag) {
                            case 1: {
                                const v_1 = _arg_3.fields[0];
                                return mkContent(empty(), v_1.Label, mkContentWeatherValue(v_1, (value_69) => singleton(["style", createObj(toList(delay(() => append(iconMargin, delay(() => singleton_1(["transform", ("rotate(" + ~~rotateDirection180(value_69)) + "deg)"]))))))])));
                            }
                            case 3:
                                return mkContent(empty(), _arg_3.fields[0].Label, ViewHelpers_mkRow(singleton(ViewHelpers_mkColumn(toList(delay(() => singleton_1(defaultArg(map_1((l) => ViewHelpers_divWith([["style", {
                                    width: (columnWidth_1 * 0.75) + "vw",
                                    height: (30 - 13) + "vh",
                                }]], toList(delay(() => {
                                    let key_46, xs_3, p_1_10, props_11, p_1_9, props_12, arg_2, arg_1_1, arg_2_1, arg_3;
                                    const url = map_marker;
                                    return singleton_1((key_46 = toString_1(sensor), (xs_3 = [(p_1_10 = ofList(map((value_86) => value_86, ofArray([["center", l], ["zoom", 14], ["children", ofArray([(props_11 = {
                                        attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
                                        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                                    }, react.createElement(TileLayer, props_11)), (p_1_9 = ofList(map((value_85) => value_85, ofArray([["position", l], ["icon", (props_12 = ofArray([["iconUrl", url], ["iconSize", [24, 24]], ["iconAnchor", [12, 12]]]), (arg_2 = toString_1(map_1((x_14) => x_14, FSharpMap__TryFind(ofList(map((value_80) => value_80, props_12), {
                                        Compare: comparePrimitives,
                                    }), "iconUrl"))), (arg_1_1 = map_1((x_16) => x_16, FSharpMap__TryFind(ofList(map((value_82) => value_82, props_12), {
                                        Compare: comparePrimitives,
                                    }), "iconSize")), (arg_2_1 = map_1((x_18) => x_18, FSharpMap__TryFind(ofList(map((value_83) => value_83, props_12), {
                                        Compare: comparePrimitives,
                                    }), "iconAnchor")), (arg_3 = map_1((x_20) => x_20, FSharpMap__TryFind(ofList(map((value_84) => value_84, props_12), {
                                        Compare: comparePrimitives,
                                    }), "popupAnchor")), new Icon({iconUrl: arg_2, iconSize: arg_1_1, iconAnchor: arg_2_1, popupAnchor: arg_3}))))))]])), {
                                        Compare: comparePrimitives,
                                    }), createElement(Marker_View, {
                                        p: p_1_9,
                                    }))])]])), {
                                        Compare: comparePrimitives,
                                    }), createElement(MapContainer_View, {
                                        p: p_1_10,
                                    }))], react.createElement(react.Fragment, {
                                        key: key_46,
                                    }, ...xs_3))));
                                }))), orElse(bind((w_1) => w_1.Location, weather), bind((s_3) => s_3.Location, sensor))), defaultOf()))))))));
                            case 2: {
                                const v_3 = _arg_3.fields[0];
                                const aspect = (columnWidth_1 * viewPort.Width) / ((30 - 12) * viewPort.Height);
                                return mkContent(ofArray([["onClick", (_arg_5) => {
                                    v_3.OnClick();
                                }], ["style", {
                                    cursor: "pointer",
                                }]]), v_3.Label, ViewHelpers_mkRow(singleton(ViewHelpers_divWith([["style", {
                                    width: columnWidth_1 + "vw",
                                }]], singleton(Graph_trendChart(ctx, aspect, state_1))))));
                            }
                            case 4: {
                                const v_4 = _arg_3.fields[0];
                                const mergeTransforms = (xs_4) => ["transform", join(" ", reverse(map((x_25) => x_25[1], xs_4)))];
                                const ship_at_quay = ship_at_quay_2(theme(new Color(3, [])), v_4.Flip);
                                return mkContent(empty(), v_4.Label, ViewHelpers_mkRow(singleton(ViewHelpers_divWith([["style", {
                                    display: "block",
                                    margin: "auto",
                                }]], singleton(createElement("svg", createObj(ofArray([["viewBox", (((((0 + " ") + 0) + " ") + ~~ship_at_quay.Width) + " ") + ~~ship_at_quay.Height], svg_width(columnWidth_1 + "vw"), svg_height(30 + "vh"), mergeTransforms(v_4.WholeSvgTransform), (elements_15 = ofArray([createElement("g", createObj(ofArray([mergeTransforms(v_4.SvgTransform), ["children", reactApi.Children.toArray([v_4.Svg])]]))), createElement("g", {
                                    children: reactApi.Children.toArray([v_4.SvgArrow]),
                                })]), ["children", reactApi.Children.toArray(Array.from(elements_15))])]))))))));
                            }
                            default: {
                                const v = _arg_3.fields[0];
                                return mkContent(empty(), v.Label, mkContentWeatherValue(v, (_arg_4) => singleton(["style", createObj(toList(delay(() => iconMargin)))])));
                            }
                        }
                    });
                    return ViewHelpers_divWith([["style", {
                        backgroundColor: theme(new Color(4, [])),
                        borderRadius: 1 + "vmin",
                        marginRight: (1 * 2) + "vmax",
                        marginBottom: (1 * 2) + "vmax",
                    }]], singleton(ViewHelpers_mkColumnWith([["width", columnWidth_1 + "vw"]], singleton(ViewHelpers_mkRowWith([["height", 30 + "vh"]], singleton(innerContent(x_7)))))));
                }, arg_4)), chunkBySize(columns_1, toList(delay(() => append(choose_1((d_2) => map_1((_arg_6) => {
                    if (equals(d_2.Unit, new WeatherValue_Unit(1, []))) {
                        return new WeatherValue_WeatherBox(1, [d_2]);
                    }
                    else {
                        return new WeatherValue_WeatherBox(0, [d_2]);
                    }
                }, d_2.GetValue(state_1, w)), WeatherValue_values), delay(() => append(ofArray(toArray_1(map2((d_3, quayDir) => {
                    let value_121;
                    const flipVessel = defaultArg(map_1((_arg_8) => _arg_8.QuayFlipVessel, sensor), false);
                    const ship_at_quay_1 = ship_at_quay_2(theme(new Color(3, [])), flipVessel);
                    const arrow = directionArrow(theme(new Color(3, [])), theme(new Color(4, [])));
                    const r = max(ship_at_quay_1.Width, ship_at_quay_1.Height);
                    const windRot = d_3;
                    let rot;
                    const x_27 = (quayDir - windRot) % 360;
                    rot = ((x_27 < 0) ? (360 + x_27) : x_27);
                    const moveY = op_UnaryNegation_Int32(~~(arrow.Height * ((value_121 = (((windRot / 2) * 3.141592653589793) / 180), Math.sin(value_121))))) | 0;
                    return new WeatherValue_WeatherBox(4, [{
                        Flip: flipVessel,
                        Label: "Quay",
                        Svg: ship_at_quay_1.Svg,
                        SvgArrow: arrow.Svg,
                        SvgTransform: singleton(svg_transform_rotateCenter(rot, r / 2, r / 2)),
                        WholeSvgTransform: ofArray([["transform", ("rotate(" + windRot) + ")"], ["transform", ((("translate(" + 0) + ",") + moveY) + ")"], ["transform", ("scale(" + 0.9) + ")"]]),
                    }]);
                }, tryHead_1(choose((_arg_7) => _arg_7.WindDirection, state_1.WeatherHistory)), bind((s_4) => s_4.QuayOrientation, sensor)))), delay(() => append(!isEmpty_1(state_1.WeatherHistory) ? singleton_1(new WeatherValue_WeatherBox(2, [{
                    Label: "Trend",
                    OnClick: () => {
                        dispatch(new Msg(6, []));
                    },
                }])) : empty_1(), delay(() => singleton_1(new WeatherValue_WeatherBox(3, [{
                    Label: "Position",
                }]))))))))))));
                return ViewHelpers_divWith([["style", {
                    marginTop: 1 + "vmax",
                }]], x_33);
            };
            const matchValue_3 = weather;
            return (matchValue_3 != null) ? ((matchValue_3.Weather != null) ? ((w_3 = matchValue_3.Weather, (weather_1 = matchValue_3, append(singleton_1(header(weather_1.LastUpdated)), delay(() => {
                let children_3;
                return (state_1.MainView.tag === 1) ? singleton_1((children_3 = toList(delay(() => append(ViewHelpers_spaceN(2), delay(() => singleton_1(Graph_trendChart(ctx, undefined, state_1)))))), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                }))) : singleton_1(dashboardView(w_3));
            }))))) : ((lastUpdated_2 = matchValue_3.LastUpdated, append(singleton_1(header(lastUpdated_2)), delay(() => append(singleton_1(ViewHelpers_mkText(theme(new Color(0, [])), 10, [], "No data available!")), delay(() => {
                let children_1;
                return (state_1.MainView.tag === 1) ? singleton_1((children_1 = toList(delay(() => append(ViewHelpers_spaceN(2), delay(() => singleton_1(Graph_trendChart(ctx, undefined, state_1)))))), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))) : singleton_1(dashboardView(PortWindWeather_Default_Z53C0511E(lastUpdated_2)));
            }))))))) : singleton_1(createElement("h3", {
                children: ["Loading..."],
            }));
        }))))));
    })));
}

