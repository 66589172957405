import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, string_type, int32_type, record_type, float64_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { printf, toFail, interpolate, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { empty, singleton, ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { defaultArgWith } from "../fable_modules/fable-library-js.4.19.3/Option.js";

export class Wind extends Record {
    constructor(Direction, Speed) {
        super();
        this.Direction = Direction;
        this.Speed = Speed;
    }
}

export function Wind_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Wind", [], Wind, () => [["Direction", float64_type], ["Speed", float64_type]]);
}

export class Current extends Record {
    constructor(Direction, Speed) {
        super();
        this.Direction = Direction;
        this.Speed = Speed;
    }
}

export function Current_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Current", [], Current, () => [["Direction", float64_type], ["Speed", float64_type]]);
}

export class Waves extends Record {
    constructor(Direction, Height, Period) {
        super();
        this.Direction = Direction;
        this.Height = Height;
        this.Period = Period;
    }
}

export function Waves_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Waves", [], Waves, () => [["Direction", float64_type], ["Height", float64_type], ["Period", float64_type]]);
}

export class Tide extends Record {
    constructor(Sealevel) {
        super();
        this.Sealevel = Sealevel;
    }
}

export function Tide_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Tide", [], Tide, () => [["Sealevel", float64_type]]);
}

export class Swell extends Record {
    constructor(Direction, Height, Period) {
        super();
        this.Direction = Direction;
        this.Height = Height;
        this.Period = Period;
    }
}

export function Swell_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Swell", [], Swell, () => [["Direction", float64_type], ["Height", float64_type], ["Period", float64_type]]);
}

export class Clouds extends Record {
    constructor(Coverage) {
        super();
        this.Coverage = Coverage;
    }
}

export function Clouds_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Clouds", [], Clouds, () => [["Coverage", float64_type]]);
}

export class AirTemperature extends Record {
    constructor(Temperature) {
        super();
        this.Temperature = Temperature;
    }
}

export function AirTemperature_$reflection() {
    return record_type("StenaWeather.Domain.Weather.AirTemperature", [], AirTemperature, () => [["Temperature", float64_type]]);
}

export class Precipitation extends Record {
    constructor(Precipitation) {
        super();
        this.Precipitation = Precipitation;
    }
}

export function Precipitation_$reflection() {
    return record_type("StenaWeather.Domain.Weather.Precipitation", [], Precipitation, () => [["Precipitation", float64_type]]);
}

export function PrecipitationCategoryTypeModule_value(_arg) {
    switch (_arg) {
        case 0:
            return "No precipitation";
        case 1:
            return "Snow";
        case 2:
            return "Snow and rain";
        case 3:
            return "Rain";
        case 4:
            return "Drizzle";
        case 5:
            return "Freezing rain";
        case 6:
            return "Freezing drizzle";
        default:
            throw new Error(toText(interpolate("Invalid PrecipitationCategoryType: %A%P()", [_arg])));
    }
}

export class PrecipitationCategory extends Record {
    constructor(Category, Description) {
        super();
        this.Category = (Category | 0);
        this.Description = Description;
    }
}

export function PrecipitationCategory_$reflection() {
    return record_type("StenaWeather.Domain.Weather.PrecipitationCategory", [], PrecipitationCategory, () => [["Category", int32_type], ["Description", string_type]]);
}

export function WeatherSymbolTypeModule_value(_arg) {
    switch (_arg) {
        case 1:
            return "Clear sky";
        case 2:
            return "Nearly clear sky";
        case 3:
            return "Variable cloudiness";
        case 4:
            return "Half clear sky";
        case 5:
            return "Cloudy sky";
        case 6:
            return "Overcast";
        case 7:
            return "Fog";
        case 8:
            return "Light rain showers";
        case 9:
            return "Moderate rain showers";
        case 10:
            return "Heavy rain showers";
        case 11:
            return "Thunderstorm";
        case 12:
            return "Light sleet showers";
        case 13:
            return "Moderate sleet showers";
        case 14:
            return "Heavy sleet showers";
        case 15:
            return "Light snow showers";
        case 16:
            return "Moderate snow showers";
        case 17:
            return "Heavy snow showers";
        case 18:
            return "Light rain";
        case 19:
            return "Moderate rain";
        case 20:
            return "Heavy rain";
        case 21:
            return "Thunder";
        case 22:
            return "Light sleet";
        case 23:
            return "Moderate sleet";
        case 24:
            return "Heavy sleet";
        case 25:
            return "Light snowfall";
        case 26:
            return "Moderate snowfall";
        case 27:
            return "Heavy snowfall";
        default:
            throw new Error(`invalid WeatherSymbolType: ${_arg}`);
    }
}

export class WeatherSymbol extends Record {
    constructor(Code, Description) {
        super();
        this.Code = (Code | 0);
        this.Description = Description;
    }
}

export function WeatherSymbol_$reflection() {
    return record_type("StenaWeather.Domain.Weather.WeatherSymbol", [], WeatherSymbol, () => [["Code", int32_type], ["Description", string_type]]);
}

export class WeatherType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Wind10m", "Wind50m", "Current", "Waves", "Tide", "PrimarySwell", "SecondarySwell", "WindWaves", "Clouds", "AirTemperature", "Precipitation", "PrecipitationCategory", "WeatherSymbol"];
    }
}

export function WeatherType_$reflection() {
    return union_type("StenaWeather.Domain.Weather.WeatherType", [], WeatherType, () => [[["Item", Wind_$reflection()]], [["Item", Wind_$reflection()]], [["Item", Current_$reflection()]], [["Item", Waves_$reflection()]], [["Item", Tide_$reflection()]], [["Item", Swell_$reflection()]], [["Item", Swell_$reflection()]], [["Item", Waves_$reflection()]], [["Item", Clouds_$reflection()]], [["Item", AirTemperature_$reflection()]], [["Item", Precipitation_$reflection()]], [["Item", PrecipitationCategory_$reflection()]], [["Item", WeatherSymbol_$reflection()]]]);
}

export function WeatherTypeModule_createWind10m(direction, speed) {
    return new WeatherType(0, [new Wind(direction, speed)]);
}

export function WeatherTypeModule_createCurrent(direction, speed) {
    return new WeatherType(2, [new Current(direction, speed)]);
}

export function WeatherTypeModule_createWaves(direction, height, period) {
    return new WeatherType(3, [new Waves(direction, height, period)]);
}

export function WeatherTypeModule_createTide(sealevel) {
    return new WeatherType(4, [new Tide(sealevel)]);
}

export function WeatherTypeModule_createPrimarySwell(direction, height, period) {
    return new WeatherType(5, [new Swell(direction, height, period)]);
}

export function WeatherTypeModule_createSecondarySwell(direction, height, period) {
    return new WeatherType(6, [new Swell(direction, height, period)]);
}

export function WeatherTypeModule_createWindWaves(direction, height, period) {
    return new WeatherType(7, [new Waves(direction, height, period)]);
}

export function WeatherTypeModule_createClouds(cover) {
    return new WeatherType(8, [new Clouds(cover)]);
}

export function WeatherTypeModule_createAirTemperature(temperature) {
    return new WeatherType(9, [new AirTemperature(temperature)]);
}

export function WeatherTypeModule_createPrecipitation(precip) {
    return new WeatherType(10, [new Precipitation(precip)]);
}

export function WeatherTypeModule_createPrecipitationCategory(cat) {
    return new WeatherType(11, [new PrecipitationCategory(cat, PrecipitationCategoryTypeModule_value(cat))]);
}

export function WeatherTypeModule_createWeatherSymbol(symbol) {
    return new WeatherType(12, [new WeatherSymbol(symbol, WeatherSymbolTypeModule_value(symbol))]);
}

export function WeatherTypeModule_tryGetWind10m(_arg) {
    if (_arg.tag === 0) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetWind50m(_arg) {
    if (_arg.tag === 1) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetCurrent(_arg) {
    if (_arg.tag === 2) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetWave(_arg) {
    if (_arg.tag === 3) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetTide(_arg) {
    if (_arg.tag === 4) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetPrimarySwell(_arg) {
    if (_arg.tag === 5) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetSecondarySwell(_arg) {
    if (_arg.tag === 6) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetWindWaves(_arg) {
    if (_arg.tag === 7) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetClouds(_arg) {
    if (_arg.tag === 8) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetAirTemperature(_arg) {
    if (_arg.tag === 9) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetPrecipitation(_arg) {
    if (_arg.tag === 10) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetPrecipitationCategory(_arg) {
    if (_arg.tag === 11) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_tryGetWeatherSymbol(_arg) {
    if (_arg.tag === 12) {
        return _arg.fields[0];
    }
    else {
        throw new Error(`Invalid weather type: ${_arg}`);
    }
}

export function WeatherTypeModule_getValues(_arg) {
    switch (_arg.tag) {
        case 1: {
            const w_1 = _arg.fields[0];
            return ofArray([w_1.Direction, w_1.Speed]);
        }
        case 2: {
            const w_2 = _arg.fields[0];
            return ofArray([w_2.Direction, w_2.Speed]);
        }
        case 3: {
            const w_3 = _arg.fields[0];
            return ofArray([w_3.Direction, w_3.Height, w_3.Period]);
        }
        case 4:
            return singleton(_arg.fields[0].Sealevel);
        case 5: {
            const w_5 = _arg.fields[0];
            return ofArray([w_5.Direction, w_5.Height, w_5.Period]);
        }
        case 6: {
            const w_6 = _arg.fields[0];
            return ofArray([w_6.Direction, w_6.Height, w_6.Period]);
        }
        case 7: {
            const w_7 = _arg.fields[0];
            return ofArray([w_7.Direction, w_7.Height, w_7.Period]);
        }
        case 8:
            return singleton(_arg.fields[0].Coverage);
        case 9:
            return singleton(_arg.fields[0].Temperature);
        case 10:
            return singleton(_arg.fields[0].Precipitation);
        case 11:
            return singleton(_arg.fields[0].Category);
        case 12:
            return empty();
        default: {
            const w = _arg.fields[0];
            return ofArray([w.Direction, w.Speed]);
        }
    }
}

export const WeatherTypeModule_checkValue = (() => {
    const checkDir = (x) => {
        if (x <= 360) {
            return x >= 0;
        }
        else {
            return false;
        }
    };
    const checkSpeed = (x_1) => {
        if (x_1 >= 0) {
            return x_1 < 50;
        }
        else {
            return false;
        }
    };
    const checkHeight = (x_2) => {
        if (x_2 >= 0) {
            return x_2 < 50;
        }
        else {
            return false;
        }
    };
    const checkPeriod = (x_4) => {
        if (x_4 >= 0) {
            return x_4 < 50;
        }
        else {
            return false;
        }
    };
    const checkPercent = (x_5) => {
        if (x_5 >= 0) {
            return x_5 <= 100;
        }
        else {
            return false;
        }
    };
    return (_arg) => {
        switch (_arg.tag) {
            case 1: {
                const w_1 = _arg.fields[0];
                return checkDir(w_1.Direction) && checkSpeed(w_1.Speed);
            }
            case 2: {
                const w_2 = _arg.fields[0];
                return checkDir(w_2.Direction) && checkSpeed(w_2.Speed);
            }
            case 3: {
                const w_3 = _arg.fields[0];
                return (checkDir(w_3.Direction) && checkHeight(w_3.Height)) && checkPeriod(w_3.Period);
            }
            case 4: {
                const x_3 = _arg.fields[0].Sealevel;
                return (x_3 >= -50) && (x_3 < 50);
            }
            case 5: {
                const w_5 = _arg.fields[0];
                return (checkDir(w_5.Direction) && checkHeight(w_5.Height)) && checkPeriod(w_5.Period);
            }
            case 6: {
                const w_6 = _arg.fields[0];
                return (checkDir(w_6.Direction) && checkHeight(w_6.Height)) && checkPeriod(w_6.Period);
            }
            case 7: {
                const w_7 = _arg.fields[0];
                return (checkDir(w_7.Direction) && checkHeight(w_7.Height)) && checkPeriod(w_7.Period);
            }
            case 8:
                return checkPercent(_arg.fields[0].Coverage);
            case 9:
                return checkPercent(_arg.fields[0].Temperature);
            case 10:
                return checkPercent(_arg.fields[0].Precipitation);
            case 11:
                return checkPercent(_arg.fields[0].Category);
            case 12:
                return checkPercent(_arg.fields[0].Code);
            default: {
                const w = _arg.fields[0];
                return checkDir(w.Direction) && checkSpeed(w.Speed);
            }
        }
    };
})();

export function WeatherTypeModule_declaringType(_arg) {
    switch (_arg.tag) {
        case 1:
            return Wind_$reflection();
        case 2:
            return Current_$reflection();
        case 3:
            return Waves_$reflection();
        case 4:
            return Tide_$reflection();
        case 5:
            return Swell_$reflection();
        case 6:
            return Swell_$reflection();
        case 7:
            return Waves_$reflection();
        case 8:
            return Clouds_$reflection();
        case 9:
            return AirTemperature_$reflection();
        case 10:
            return Precipitation_$reflection();
        case 11:
            return PrecipitationCategory_$reflection();
        case 12:
            return WeatherSymbol_$reflection();
        default:
            return Wind_$reflection();
    }
}

export class WeatherTypeModule_Parameter extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Wind10mParam", "Wind50mParam", "CurrentParam", "WavesParam", "TideParam", "PrimarySwellParam", "SecondarySwellParam", "WindWavesParam", "CloudsParam", "AirTemperatureParam", "PrecipitationParam", "WeatherSymbolParam", "PrecipitationCategoryParam"];
    }
}

export function WeatherTypeModule_Parameter_$reflection() {
    return union_type("StenaWeather.Domain.Weather.WeatherTypeModule.Parameter", [], WeatherTypeModule_Parameter, () => [[], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class WeatherTypeModule_ParameterAttributeMetadata extends Record {
    constructor(Name, Type, UnitOfMeasure) {
        super();
        this.Name = Name;
        this.Type = Type;
        this.UnitOfMeasure = UnitOfMeasure;
    }
}

export function WeatherTypeModule_ParameterAttributeMetadata_$reflection() {
    return record_type("StenaWeather.Domain.Weather.WeatherTypeModule.ParameterAttributeMetadata", [], WeatherTypeModule_ParameterAttributeMetadata, () => [["Name", string_type], ["Type", string_type], ["UnitOfMeasure", string_type]]);
}

export const WeatherTypeModule_ParameterModule_all = ofArray([new WeatherTypeModule_Parameter(0, []), new WeatherTypeModule_Parameter(1, []), new WeatherTypeModule_Parameter(2, []), new WeatherTypeModule_Parameter(3, []), new WeatherTypeModule_Parameter(4, []), new WeatherTypeModule_Parameter(5, []), new WeatherTypeModule_Parameter(6, []), new WeatherTypeModule_Parameter(7, []), new WeatherTypeModule_Parameter(8, []), new WeatherTypeModule_Parameter(9, []), new WeatherTypeModule_Parameter(10, []), new WeatherTypeModule_Parameter(12, []), new WeatherTypeModule_Parameter(11, [])]);

export function WeatherTypeModule_ParameterModule_metadata(_arg) {
    switch (_arg.tag) {
        case 3:
        case 5:
        case 6:
        case 7:
            return ofArray([new WeatherTypeModule_ParameterAttributeMetadata("Direction", "float", "degree 0-359.9 (nautical standard)"), new WeatherTypeModule_ParameterAttributeMetadata("Height", "float", "m"), new WeatherTypeModule_ParameterAttributeMetadata("Period", "float", "s")]);
        case 4:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Sealevel", "float", "m"));
        case 8:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Coverage", "float", "fraction"));
        case 9:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Temperature", "float", "°C"));
        case 10:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Precipitation", "float", "mm/h"));
        case 11:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Code", "int", "code"));
        case 12:
            return singleton(new WeatherTypeModule_ParameterAttributeMetadata("Category", "int", "code"));
        default:
            return ofArray([new WeatherTypeModule_ParameterAttributeMetadata("Direction", "float", "degree 0-359.9 (nautical standard)"), new WeatherTypeModule_ParameterAttributeMetadata("Speed", "float", "m/s")]);
    }
}

export function WeatherTypeModule_ParameterModule_fromWeatherType(_arg) {
    switch (_arg.tag) {
        case 1:
            return new WeatherTypeModule_Parameter(1, []);
        case 2:
            return new WeatherTypeModule_Parameter(2, []);
        case 3:
            return new WeatherTypeModule_Parameter(3, []);
        case 4:
            return new WeatherTypeModule_Parameter(4, []);
        case 5:
            return new WeatherTypeModule_Parameter(5, []);
        case 6:
            return new WeatherTypeModule_Parameter(6, []);
        case 7:
            return new WeatherTypeModule_Parameter(7, []);
        case 8:
            return new WeatherTypeModule_Parameter(8, []);
        case 9:
            return new WeatherTypeModule_Parameter(9, []);
        case 10:
            return new WeatherTypeModule_Parameter(10, []);
        case 11:
            return new WeatherTypeModule_Parameter(12, []);
        case 12:
            return new WeatherTypeModule_Parameter(11, []);
        default:
            return new WeatherTypeModule_Parameter(0, []);
    }
}

export function WeatherTypeModule_ParameterModule_tryParse(s) {
    const matchValue = s.toUpperCase();
    switch (matchValue) {
        case "WIND10M":
            return new WeatherTypeModule_Parameter(0, []);
        case "WIND50M":
            return new WeatherTypeModule_Parameter(1, []);
        case "CURRENT":
            return new WeatherTypeModule_Parameter(2, []);
        case "WAVE":
        case "WAVES":
            return new WeatherTypeModule_Parameter(3, []);
        case "TIDE":
            return new WeatherTypeModule_Parameter(4, []);
        case "PRIMARYSWELL":
            return new WeatherTypeModule_Parameter(5, []);
        case "SECONDARYSWELL":
            return new WeatherTypeModule_Parameter(6, []);
        case "WINDWAVE":
        case "WINDWAVES":
            return new WeatherTypeModule_Parameter(7, []);
        case "CLOUDS":
            return new WeatherTypeModule_Parameter(8, []);
        case "AIRTEMPERATURE":
            return new WeatherTypeModule_Parameter(9, []);
        case "PRECIPITATION":
            return new WeatherTypeModule_Parameter(10, []);
        case "PRECIPITATIONCATEGORY":
            return new WeatherTypeModule_Parameter(12, []);
        case "WEATHERSYMBOL":
            return new WeatherTypeModule_Parameter(11, []);
        default:
            return undefined;
    }
}

export function WeatherTypeModule_ParameterModule_name(_arg) {
    switch (_arg.tag) {
        case 1:
            return "wind50m";
        case 2:
            return "current";
        case 3:
            return "wave";
        case 4:
            return "tide";
        case 5:
            return "primaryswell";
        case 6:
            return "secondaryswell";
        case 7:
            return "windwave";
        case 8:
            return "clouds";
        case 9:
            return "airtemperature";
        case 10:
            return "precipitation";
        case 12:
            return "precipitationcategory";
        case 11:
            return "weathersymbol";
        default:
            return "wind10m";
    }
}

export function WeatherTypeModule_ParameterModule_parse(s) {
    return defaultArgWith(WeatherTypeModule_ParameterModule_tryParse(s), () => toFail(printf("Cannot parse WeatherParameter from value %s"))(s));
}

